export const formatNumber = (number) => {
  // Check if the input is a valid number
  if (isNaN(Number(number))) {
    return number; // Return the input unchanged if not a valid number
  }

  // Convert the number to a string and format with commas
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const formatDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with 0 if needed
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = date.getFullYear(); // Get full year

  return `${day}/${month}/${year}`;
};
