// import { spareListUrl } from "../../../config/config";
// import axiosInstance from "../../axios-middleware/axiosMiddleware";

// export const spareListRequest = async (filters, user_id, medium) => {
//   try {
//     const response = await axiosInstance.get(
//       spareListUrl(user_id, medium),

//       {
//         params: filters,
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     console.log(response.data.data);

//     return response.data.data;
//   } catch (error) {
//     throw error; // Re-throw the original error
//   }
// };







import { spareListUrl } from "../../../config/config";
import axiosInstance from "../../axios-middleware/axiosMiddleware";

export const spareListRequest = async ({ pageParam = null, filters, user_id, medium }) => {
  try {
    // Construct query parameters
    const params = {
      ...filters,
      pagination:1,
      cursor: pageParam ?? undefined, // Avoid sending `null`
    };

    // Make API request
    const response = await axiosInstance.get(spareListUrl(user_id, medium), { params });

    // Debugging: Log the response structure
    console.log("API Response:", response.data);

    return {
      data: response.data.data || [], // Ensure `data` is always an array
      nextCursor: response.data.nextCursor ?? null, // Ensure `nextCursor` exists
    };
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
