import React, { Fragment, useCallback, useEffect, useRef } from "react";
import classes from "./spareListPage.module.css";
import useGetSpareList from "../../tanstack-query/spares/useGetSpareList";
import { SpareItem } from "../../components/spares/SpareItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SparesFilterPage } from "./filters/sparesFilter/SparesFilterPage";

import Cookies from "js-cookie";
import useAddToWishListMutation from "../../tanstack-query/wishList/useAddToWishListMutation";
import { toast } from "react-toastify";
import { BannerSkeleton } from "../../components/skeletons/bannerSkeleton/BannerSkeleton";
import { ProductSkeleton } from "../../components/skeletons/productSkeleton/ProductSkeleton";
import { BestSellingCardMessage } from "../../components/skeletons/bestSellingCardMessage/BestSellingCardMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterOptions,
  setFilterOption,
} from "../../store/category/categorySlice";

import { Banner } from "../../components/banner/Banner";
import useGetAdvertisement from "../../tanstack-query/advertisement/useGetAdvertisement";
import { Spinner } from "../../components/ui/spinner/Spinner";

export const SpareListPage = () => {
  const filters = useSelector(selectFilterOptions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = Cookies.get("authToken");
  const userId = Cookies.get("user_id");
  const guestId = Cookies.get("guestId");
  const medium = authToken ? "user" : "guest";
  const user_id = authToken ? userId : guestId;
  const advertisementFilters = { category: "spares", page: "listing" };

  const [searchParams] = useSearchParams();
  const {
    data: add,
    isSuccess: addIsSuccess,
    isLoading: addIsLoading,
  } = useGetAdvertisement(advertisementFilters);

  const {
    data: sparesListData,
    isLoading,
    isSuccess,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSpareList(filters, user_id, medium);

  const { mutateAsync } = useAddToWishListMutation();

  const loadMoreRef = useRef(null);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );
  

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 1 });
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    const newFilters = [
      "brand",
      "spare",
      "model",
      "start",
      "end",
      "sort",
    ].reduce(
      (acc, key) => ({ ...acc, [key]: searchParams.get(key) || null }),
      {}
    );

    dispatch(setFilterOption(newFilters));
  }, [dispatch, searchParams]);

  const navigateToSpareDetail = useCallback(
    (requestId) => navigate(`${requestId}`),
    [navigate]
  );

  const handleAddToWishList = useCallback(
    async (item) => {
      try {
        const response = await mutateAsync({
          category_id: item.category_id,
          item_id: item.id,
          master_product_id: item.master_product_id,
        });

        toast.success(response?.data?.message?.displayMessage);
      } catch (error) {
        toast.error(error?.response?.data?.message?.displayMessage);
      }
    },
    [mutateAsync]
  );
  console.log("sparesListData ", sparesListData);

  return (
    <div className={classes.box}>
      <SparesFilterPage />
      {addIsLoading ? (
        <BannerSkeleton />
      ) : addIsSuccess && add?.data?.length > 0 ? (
        <Banner data={add?.data} />
      ) : null}
      <div className={classes.box__itemList}>
        {isLoading ? (
          <ProductSkeleton />
        ) : isSuccess && sparesListData?.pages?.length === 1 ? (
          <BestSellingCardMessage />
        ) : (
          sparesListData.pages.reduce((acc, page, index) => {
            if (!page?.data) return acc;
            return [
              ...acc,
              <Fragment key={index}>
                {page.data.map((spareItem) => (
                  <SpareItem
                    key={spareItem.id}
                    item={spareItem}
                    onClick={navigateToSpareDetail}
                    onWishList={() => handleAddToWishList(spareItem)}
                  />
                ))}
              </Fragment>,
            ];
          }, [])
        )}
        {isLoading && <ProductSkeleton />}

        {/* {!isLoading && isSuccess && sparesListData?.pages?.length > 0
          ? sparesListData.pages.reduce((acc, page, index) => {
              if (!page?.data) return acc;
              return [
                ...acc,
                <Fragment key={index}>
                  {page.data.map((spareItem) => (
                    <SpareItem
                      key={spareItem.id}
                      item={spareItem}
                      onClick={navigateToSpareDetail}
                      onWishList={() => handleAddToWishList(spareItem)}
                    />
                  ))}
                </Fragment>,
              ];
            }, [])
          : !isLoading && <BestSellingCardMessage />} */}

        {/* Invisible div for infinite scroll */}
        <div ref={loadMoreRef} />

        {isFetchingNextPage && <Spinner />}
      </div>
    </div>
  );
};
