import React from "react";
import classes from "./bestSellingProductPage.module.css";
import { useGetBestSparesProductList } from "../../../tanstack-query/bestSellingProduct/useGetBestSellingProductList";
import { Link } from "react-router-dom";
import { BestSellingSpares } from "../../../components/bestSellingProduct/bestSellingSpares/BestSellingSpares";
import { BestSellingSkeleton } from "../../../components/skeletons/bestSellingSkeleton/BestSellingSkeleton";
import { BestSellingCardMessage } from "../../../components/skeletons/bestSellingCardMessage/BestSellingCardMessage";

export const BestSellingSparesPage = () => {
  const { data, isSuccess, isLoading } = useGetBestSparesProductList();

  // Extract the spares array safely
  const spares = data?.data?.data || [];
  console.log(isSuccess)

  return (
    <div className={classes.box}>
      <div className={classes.box__content}>
        <div className={classes.box__content__category}>
          <h1 className={classes.box__content__category__title}>
            Spares (Hot Deals)
          </h1>
          <Link
            to="spares"
            className={classes.box__content__category__link}
          ></Link>
        </div>

        <div className={classes.box__content__container}>
          {isLoading ? (
            <BestSellingSkeleton />
          ) : isSuccess && spares.length === 0 ? (
            <BestSellingCardMessage />
          ) : (
            spares.map((spare) => (
              <BestSellingSpares spare={spare} key={spare.id} />
            ))
          )}
        </div>
        

        <hr className={classes.box__content__sep} />
      </div>
    </div>
  );
};
