import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Outlet } from "react-router-dom";
import classes from "./rootLayout.module.css";
import { Footer } from "../components/footer/Footer";
import {
  generateId,
  getAuthToken,
  getTokenDuration,
} from "../utils/helpers/getAuth";
import { checkAndRemoveExpiredToken } from "../utils/https-request/auth/otpVerificationRequest";

export const RootLayout = () => {
  useEffect(() => {
    checkAndRemoveExpiredToken();
    generateId();

    const token = getAuthToken();
    if (!token || token === "EXPIRED") {
      removeAuthToken();
      return;
    }

    const tokenDuration = getTokenDuration();
    console.log("Token Duration:", tokenDuration);

    if (tokenDuration <= 0) {
      removeAuthToken();
      return;
    }

    // Automatically remove the authToken when it expires
    const timeoutId = setTimeout(() => {
      console.warn("Auth token expired! Removing...");
      removeAuthToken();
    }, tokenDuration);

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.container__outlet}>
        <Outlet />
      </div>
      <div className={classes.container__footer}>
        <Footer />
      </div>
    </div>
  );
};

// ✅ Centralized function for token removal
const removeAuthToken = () => {
  Cookies.remove("authToken");
  localStorage.removeItem("authToken"); // Safari/iPhone Fix
  localStorage.removeItem("expiryTimestamp");
};
