import React from "react";
import classes from "./searchBar.module.css";
import { SearchInput } from "../../searchInput/SearchInput";
import { BackButton } from "./backButton/BackButton";

export const SearchBar = ({ placeholder }) => {
  return (
    <div className={classes.box}>
      <BackButton />
      <div className={classes.box__search}>
        <SearchInput placeholder={placeholder} />
      </div>
    </div>
  );
};
