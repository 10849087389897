import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./backButton.module.css";

export const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [lastBackPress, setLastBackPress] = useState(0); // Store last back press time

  useEffect(() => {
    const handleBackPress = () => {
      const now = Date.now();

      if (location.pathname === "/") {
        if (now - lastBackPress < 2000) {
          window.history.back(); // Allow exit
        } else {
          setLastBackPress(now);
          alert("Press back again to exit.");
        }
      } else {
        navigate(-1); // Go back
      }
    };

    // ✅ Android & Windows Back Button Handling
    window.addEventListener("popstate", handleBackPress);

    // ✅ iPhone (Safari & WebView) History Override
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      const overrideHistory = () => {
        window.history.pushState(null, "", window.location.href);
      };
      overrideHistory();
      window.addEventListener("popstate", overrideHistory);

      return () => {
        window.removeEventListener("popstate", overrideHistory);
      };
    }

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
  }, [navigate, location, lastBackPress]);

  return <button className={classes.box__btn} onClick={() => navigate(-1)} />;
};
