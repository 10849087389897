import { React, useEffect } from "react";
import { App as CapacitorApp } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences"; // ✅ Import Preferences
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import Cookies from "js-cookie";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { mainRoutes } from "./router/router";

import "./App.css";

const router = createBrowserRouter(mainRoutes);

const restorePreferenceskey = async (key) => {
  const { value } = await Preferences.get({ key: key });

  if (value) {
    await Preferences.set({ key: key, value: String(value) });

    Cookies.set(key, value, {
      secure: true,
      sameSite: "None",
      path: "/",
    });
    console.log("Value restored from Preferences to Cookies:", value);
  }
};

function App() {
  useEffect(() => {
    restorePreferenceskey("authToken");
    restorePreferenceskey("user_id");
    restorePreferenceskey("expiryTimestamp");
    const adjustStatusBar = async () => {
      if (Capacitor.getPlatform() !== 'web') {  // ✅ Prevents errors on web
      await StatusBar.setOverlaysWebView({ overlay: false });
      await StatusBar.setStyle({ style: Style.Light });
      await StatusBar.show();
      // await StatusBar.setBackgroundColor()
      }
    };
    adjustStatusBar();
  }, []);

  useEffect(() => {
    const backButtonListener = CapacitorApp.addListener(
      "backButton",
      ({ canGoBack }) => {
        if (canGoBack) {
          window.history.back();
        } else {
          CapacitorApp.exitApp();
        }
      }
    );

    return () => {
      backButtonListener.remove(); // Clean up listener
    };
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
